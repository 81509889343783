import {
  Box,
  Collapse,
  Grid,
  Stack,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { map } from "lodash";
import PropTypes from "prop-types";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import { headers } from "../../config/index";
import axios from "axios";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Modal from "@mui/material/Modal";
import { documnetURL } from "../senior/constant";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DataGridTable from "../../common/DataGrid";
import useLoader from "../../hooks/useLoader";

function SeniorList({ seniors, onApprove, onReject, isLoading }) {
  const { openLoader, closeLoader } = useLoader();
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);

  if (!seniors?.length && !isLoading) {
    return <Typography>No result found</Typography>;
  }
  // Document view
  const handleShowDocument = (appNumber) => {
    getFormerDocuments(appNumber);
  };

  const getFormerDocuments = async (appNumber) => {
    try {
      openLoader("get_doc_case_id");
      const url = `${documnetURL}/docControl/getDocumentsByCaseId/${appNumber}/KFresh`;
      const res = await axios.get(url, {
        headers: headers,
      });
      if (res) {
        let modifiedData = res?.data.map((item, index) => ({
          ...item,
          id: index + 1,
          docCat: item.docCat == "kfresh"? item.docType : item.docCat
        }));
        setTableData(modifiedData);
        setOpenModal((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("get_doc_case_id");
    }
  };
  const COLUMN = [
    {
      field: "id",
      headerName: "S.NO",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "docCat",
      headerName: "Document Type",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "grid_header",
      flex: 1,
      renderCell: ({ row: { documentId = null, sourceCaseId = null } }) => {
        return (
          <Stack direction="row" alignItems={"center"}>
            <Button
              startIcon={<VisibilityOutlinedIcon />}
              sx={{
                color: "#44A0E3",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "0px !important",
                "& .css-1d6wzja-MuiButton-startIcon": {
                  marginRight: "3px !important",
                },
              }}
              onClick={(e) => handlePDFDownload(e, documentId, sourceCaseId)}
            ></Button>
          </Stack>
        );
      },
    },
  ];
  const handlePDFDownload = async (e, documentId, sourceCaseId) => {
    try {
      openLoader("handle_pdf_download");
      const url = `${documnetURL}/docControl/downloadDocumentBy/${documentId}/${sourceCaseId}/KFresh`;
      const response = await axios.post(
        url,
        {},
        { responseType: "blob", headers: headers }
      );
      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);
        setPdfUrl(pdfUrl);
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (err) {
      console.log("ERROR", err);
    } finally {
      closeLoader("handle_pdf_download");
      // hideLoader();
    }
  };
  const toggleFullscreen = () => {
    const element = document.querySelector(".fullscreen-object");
    if (!document.fullscreenElement) {
      element.requestFullscreen().catch((err) => {
        console.log(
          `Error attempting to enable full-screen mode: ${err.message}`
        );
      });
    } else {
      document.exitFullscreen();
    }
  };
  return (
    <Stack sx={{ p: "1rem", flexGrow: 1 }}>
      <Stack gap={1}>
        {map(seniors, (senior) => {
          const {
            firstName,
            lastName,
            appNumber,
            income,
            householdSize,
            appStatus,
          } = senior;
          const isOpen = false;
          return (
            <Stack direction="row" className="vendorContainer">
              <Grid container>
                <Grid item xs={9}>
                  <Box>
                    <Grid container>
                      <Grid item xs={6} md={3}>
                        <Stack className="textContent">
                          <p className="contentTitle">Application No</p>
                          <p className="contentSubTitle">{appNumber}</p>
                        </Stack>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Stack className="textContent">
                          <p className="contentTitle">Name</p>
                          <p className="contentSubTitle">{`${firstName} ${lastName}`}</p>
                        </Stack>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Stack className="textContent">
                          <p className="contentTitle">Household size</p>
                          <p
                            className="contentSubTitle"
                            style={{ marginLeft: "1rem" }}
                          >
                            {householdSize}
                          </p>
                        </Stack>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Stack className="textContent">
                          <p className="contentTitle">Income</p>
                          <p className="contentSubTitle">${income}</p>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Grid
                    container
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    <Grid item xs={10}>
                      {" "}
                      {appStatus === "WL" ? (
                        <Stack gap={1} direction="row" alignItems="center">
                          <Button
                            variant="outlined"
                            color="success"
                            onClick={() => onApprove(appNumber)}
                            startIcon={<DoneOutlinedIcon />}
                            style={{ textTransform: "none", width: "100px" }}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => onReject(appNumber)}
                            startIcon={<CloseIcon />}
                            style={{ textTransform: "none", width: "100px" }}
                          >
                            Deny
                          </Button>
                        </Stack>
                      ) : appStatus === "AP" ? (
                        <span className="badge badge-primary">Approved</span>
                      ) : (
                        <span className="badge badge-danger">Denied</span>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                    {appStatus !== "WL" && 
                      <IconButton
                        aria-label="view"
                        onClick={() => handleShowDocument(appNumber)}
                      >
                        <FilePresentOutlinedIcon />
                      </IconButton>}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Collapse in={isOpen}>
                {/* {isOpen && <Box>{renderDetails(vendor)}</Box>} */}
              </Collapse>
            </Stack>
          );
        })}
      </Stack>
      <div>
        <Modal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            setPdfUrl(null);
            if (document.fullscreenElement) {
              document.exitFullscreen();
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              bgcolor: "background.paper",
              p: 4,
              "& .css-levciy-MuiTablePagination-displayedRows": {
                margin: "0px !important",
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                justifyContent: "center !important",
              },
              "& .css-wstmaz-MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
                justifyContent: "center",
              },
              "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                opacity: "0 !important",
                transition: "none !important",
              },
              "& .MuiDataGrid-columnHeader": { paddingLeft: "0px !important" },
              "& .MuiDataGrid-row": { paddingLeft: "0px !important" },
              "& .css-h1igai .MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid",
                justifyContent: "center !important",
              },
            }}
          >
            <DataGridTable columns={COLUMN} data={tableData} />
            {/* Display PDF here */}
            {pdfUrl && (
              <>
                <div style={{ position: "relative" }}>
                  <Button
                    onClick={toggleFullscreen}
                    sx={{
                      position: "absolute",
                      top: "14px",
                      right: "20%",
                      zIndex: 1000,
                      color: "#f1f1f1",
                    }}
                  >
                    <FullscreenIcon />
                  </Button>
                  <object
                    className="fullscreen-object"
                    data={pdfUrl}
                    type="application/pdf"
                    width="100%"
                    height="350px"
                  ></object>
                </div>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </Stack>
  );
}

SeniorList.propTypes = {
  seniors: PropTypes.array,
  onReject: PropTypes.func,
  onApprove: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default SeniorList;
