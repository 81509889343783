import * as React from "react";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import Person3Icon from "@mui/icons-material/Person3";
import HailIcon from "@mui/icons-material/Hail";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KFresh from "../../../assets/images/KFresh.svg";
import KFreshMini from "../../../assets/images/Kfresh_mini.svg";
import { Avatar, Typography } from "@mui/material";
import { ReactComponent as BookIcon } from "../../../assets/icons/book.svg";
import { ReactComponent as ChartIcon } from "../../../assets/icons/chart.svg";
import { ReactComponent as DollarCircle } from "../../../assets/icons/dollar-circle.svg";
import { ReactComponent as HandShake } from "../../../assets/icons/hand-shake.svg";
import { ReactComponent as PlantIcon } from "../../../assets/icons/plant.svg";
import { ReactComponent as TransactionsIcon } from "../../../assets/icons/transactions.svg";
import { useLocation, useNavigate } from "react-router";
import useTranslator from "../../../hooks/useTranslator";
import { includes, noop } from "lodash";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LockResetIcon from "@mui/icons-material/LockReset";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { propTypes } from "react-bootstrap/esm/Image";

const userLogin = sessionStorage.getItem("userLogin")
  ? JSON.parse(sessionStorage.getItem("userLogin"))
  : {};

const drawerWidth = 240;
const collapsedDrawerWidth = 60;

function SideNavigation({ role, seniorInfoData, isHidden }) {
  const transProps = useTranslator(
    [
      "dashbord",
      "transactions",
      "settlement",
      "myDetails",
      "documents",
      "findMarket",
    ],
    "seniorSideBar"
  );

  let appNumber = null;
  if (
    seniorInfoData &&
    seniorInfoData.demographicDashboardData &&
    Array.isArray(seniorInfoData.demographicDashboardData.applicationModel) &&
    seniorInfoData.demographicDashboardData.applicationModel.length > 0
  ) {
    const [{ appNumber: extractedAppNumber = "" } = {}] =
      seniorInfoData.demographicDashboardData.applicationModel;
    appNumber = extractedAppNumber;
  }

  const seniorLinks = [
    {
      text: transProps.dashbord,
      icon: <DashboardOutlinedIcon />,
      to: "/seniorDashboard",
    },
    {
      text: transProps.transactions,
      icon: <ReceiptLongOutlinedIcon />,
      to: "/seniorTransaction",
    },
    {
      text: transProps.findMarket,
      icon: <LocationOnIcon />,
      to: `/kfreshfep?appId=${appNumber ?? null}`,
    },
    // {
    //   text: transProps.settlement,
    //   icon: <MonetizationOnOutlinedIcon />,
    //   to: "",
    // },
    // { text: transProps.myDetails, icon: <DescriptionOutlinedIcon />, to: "" },
    {
      text: transProps.documents,
      icon: <SourceOutlinedIcon />,
      to: "/document",
    },
    {
      text: "Reset Password",
      icon: <LockResetIcon />,
      to: "/senior-reset-password",
    },
  ];

  const farmerLinks = [
    // {
    //   text: transProps.dashbord,
    //   icon: <DashboardOutlinedIcon />,
    //   // to: "/seniorDashboard",
    // },
    {
      text: "My Profile",
      icon: <DashboardOutlinedIcon />,
      to: "/kfreshfep/mystore/hip",
    },
    {
      text: transProps.transactions,
      icon: <ReceiptLongOutlinedIcon />,
      to: "/farmer-transactions",
    },
    // {
    //   text: "Find Markets",
    //   icon: <LocalGroceryStoreOutlinedIcon />,
    //   // to: `/kfreshfep?appId=${appNumber ?? null}`,
    // },
    {
      text: transProps.documents,
      icon: <SourceOutlinedIcon />,
      to: "/farmer-document",
    },
    {
      text: "Reset Password",
      icon: <LockResetIcon />,
      to: "/farmer-reset-password",
    },
  ];

  const adminLinks = [
    {
      text: "Dashboard",
      icon: <DashboardOutlinedIcon />,
      to: "/kfreshfep/admin-dashboard",
    },
    { text: "Farmer", icon: <PlantIcon />, to: "/kfreshfep/manage-vendors" },
    {
      text: "Senior",
      icon: <Person3Icon />,
      to: "/kfreshfep/senior-requests",
    },
    {
      text: "Market Coordinator",
      icon: <HailIcon />,
      to: "/kfreshfep/manage-market-coordinator",
    },
    {
      text: "Eligibility Criteria",
      icon: <ChecklistRtlIcon />,
      to: "/kfreshfep/eligibility-criteria",
    },
    {
      text: "Access Rights",
      icon: <VpnKeyIcon />,
      to: "/kfreshfep/accessRights",
    },
    {
      text: "Reset Password",
      icon: <LockResetIcon />,
      to: "/kfreshfep/resetPassword",
    },
  ];

  const marketRegistrationLinks = [
    {
      text: "Registration",
      icon: <DashboardOutlinedIcon />,
      to: "/marketRegistration",
    },
    {
      text: "Public Info",
      icon: <SourceOutlinedIcon />,
      to: "/publicInfo",
    },
  ];

  const marketDashboardLinks = [
    {
      text: "My Profile",
      icon: <DashboardOutlinedIcon />,
      to: "/marketDashboard",
    },
    {
      text: "Documents",
      icon: <SourceOutlinedIcon />,
      to: "/market-document",
    },
    {
      text: "Reset Password",
      icon: <LockResetIcon />,
      to: "/marketCoordinator-reset-password",
    },
  ];

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const menuLinks = {
    admin: adminLinks,
    senior: seniorLinks,
    farmer: farmerLinks,
    marketRegistration: marketRegistrationLinks,
    marketDashboard: marketDashboardLinks,
  };

  const navLinks = menuLinks[role];
  const navigate = useNavigate();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [userLogin, setUserLogin] = useState({});

  // Function to toggle the collapsed state
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const checkWindowWidthAndUpdateCollapse = () => {
    const shouldCollapse = window.innerWidth <= 600;
    setIsCollapsed(shouldCollapse);
  };

  useEffect(() => {
    let loginDetails = sessionStorage.getItem("userLogin")
      ? JSON.parse(sessionStorage.getItem("userLogin"))
      : {};
    setUserLogin(loginDetails);
    checkWindowWidthAndUpdateCollapse();
    window.addEventListener("resize", checkWindowWidthAndUpdateCollapse);
    return () => {
      window.removeEventListener("resize", checkWindowWidthAndUpdateCollapse);
    };
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <Drawer
          sx={{
            width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
            flexShrink: 0,
            transition: "width 0.3s ease-in-out",
            "& .MuiDrawer-paper": {
              width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#000",
              color: "white",
              transition: "width 0.3s ease-in-out",
              position: "absolute",
              top: "72px",
              zIndex: "0",
            },
            "& .css-15b8vjn-MuiPaper-root-MuiDrawer-paper": {
              height: "calc(100vh - 6rem) !important",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <List
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {isCollapsed ? (
              <>
                {/* <img src={KFreshMini} alt="KFresh" /> */}
                <Avatar
                  sx={{
                    width: "2.3rem",
                    height: "2.3rem",
                    marginY: "15px",
                    fontSize: "14px",
                    background: "lightgrey",
                  }}
                >
                  {`${userLogin?.firstName?.charAt(0) ?? "NU"}${
                    userLogin?.lastName?.charAt(0) ?? ""
                  }`}
                </Avatar>
                <Typography variant="h6" mt={1}>
                  {`${userLogin?.firstName?.charAt(0) ?? "NU"}${
                    userLogin?.lastName?.charAt(0) ?? ""
                  }`}
                </Typography>
                <IconButton
                  onClick={toggleCollapse}
                  sx={{ alignSelf: "flex-end" }}
                >
                  <ChevronRightIcon />
                </IconButton>
              </>
            ) : (
              <>
                {/* <img src={KFresh} alt="KFresh" /> */}
                {!isHidden && (
                  <>
                    <Avatar
                      sx={{
                        width: "5rem",
                        height: "5rem",
                        marginY: "10px",
                        background: "lightgrey",
                      }}
                    >
                      {`${userLogin?.firstName?.charAt(0) ?? "NU"}${
                        userLogin?.lastName?.charAt(0) ?? ""
                      }`}
                    </Avatar>
                    <Typography variant="h6">{`${
                      userLogin?.firstName ?? "New User"
                    } ${userLogin?.lastName ?? ""}`}</Typography>
                    <IconButton
                      onClick={toggleCollapse}
                      sx={{ alignSelf: "flex-end" }}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </List>
          {!isHidden && <Divider sx={{ opacity: 0.8, background: "#fff" }} />}
          <List>
            {navLinks.map(({ text, icon, to }) => {
              const selected = location.pathname === to;
              return (
                <ListItem
                  button
                  onClick={() => (to ? navigate(to) : noop)}
                  key={text}
                  selected={selected}
                  // onClick={() => handleListItemClick(text)}
                  sx={{
                    marginTop: "10px",
                    "&.Mui-selected": {
                      backgroundColor: "#FFFFFF",
                      color: "#000000",
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#FFFFFF",
                    },
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.25)",
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: "inherit" }}>{icon}</ListItemIcon>
                  {!isCollapsed && <ListItemText primary={text} />}
                </ListItem>
              );
            })}
          </List>
          <Divider />
        </Drawer>
      </Box>
    </ThemeProvider>
  );
}

SideNavigation.propTypes = {
  role: PropTypes.string,
  seniorInfoData: PropTypes.object,
  isHidden: propTypes.bool,
};

SideNavigation.defaultProps = {
  role: "senior",
};

export default connect(mapStateToProps, undefined)(SideNavigation);
